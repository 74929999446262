import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { map, Observable, tap } from 'rxjs';

import { PageBuilderComponent } from 'shared-lib';
import { PageScope } from 'shared-lib/core';

@Component({
  standalone: true,
  selector: 'joymo-page-builder-smart',
  template: `
    <joymo-page-builder
      *ngIf="pageScope$ | async as pageScope"
      [pageScope]="pageScope"
      (onTabSelected)="tabSelected($event)"
    ></joymo-page-builder>
  `,
  imports: [PageBuilderComponent, CommonModule],
})
export class PageBuilderSmartComponent {
  readonly pageScope$: Observable<PageScope>;
  constructor(private route: ActivatedRoute, private router: Router) {
    this.pageScope$ = this.route.data.pipe(map((data) => data['pageScope']));
  }
  tabSelected(tabId: string) {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { tabId },
      queryParamsHandling: 'merge',
    });
  }
}
